.app-layout{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.app-layout__main{
  flex-grow: 1;
  display: flex;
}
.app-layout__side-bar{
  min-width: 200px;
  width: 25%;
  max-width: 280px;
  overflow-y: auto;
  background: rgba(#fff,0.2);
  border-right: 3px solid rgba(#fff,0.5);
  padding: $grid-gutter-width / 2;
}
.app-layout__view{
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.app-layout__view-inner{
  padding: $grid-gutter-width/2;

  @include media-breakpoint-down(xs) {
    padding: $grid-gutter-width/2 $grid-gutter-width/4;
  }
}
