

// NOTE
// these settings will override the default bootstrap settings, aswell as we can add our own custom vars for use elsewhere
// refer to /node_modules/bootstrap/_variables.scss for what can be overridden
//
// you'll need to import this file at the top of all your scss files in order to access the vars...

$sxs: 0.5rem;
$ssm: 1rem;
$smd: 2rem;
$smd: 2rem;


// PRE BOOTSTRAP OVERIDES
// we need to define these above bootstrap, as bootstrap uses them to construct scss objects and such
$primary: #35b8e2;
$primary-dark: #0F3440;
$primary-light: #b0e2f3;
$primary-lighter: #d8f1f9;
$primary-offwhite: #eaf7fb;
$grey-dark: #383838;
$grey-middark: #999;
$grey: #a6a4a6;
$grey-light: #cccccc;
$brown: #592a14;
$red: #c73e3e; //#e23535;
$green: #3ec781;

$font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size:                $font-size-base * 2 !default;
$h2-font-size:                $font-size-base * 1.8 !default;
$h3-font-size:                $font-size-base * 1.6 !default;
$h4-font-size:                $font-size-base * 1.4 !default;
$h5-font-size:                $font-size-base * 1.2 !default;
$h6-font-size:                $font-size-base !default;
$font-weight-normal: 300;
$font-weight-bold: 500;
$headings-font-weight: $font-weight-normal;

$body-bg: #fff;

// GRID
$grid-gutter-width: $smd;

$grid-breakpoints: (
  xs: 0,
  sm: 580px,
  md: 720px,
  lg: 1024px,
  xl: 1200px
);


// CARDS
$card-border-width: 0;


// OTHER
$border-radius: 0;


//////////////////////////////////////
// NON-BOOTSTRAP CUSTOM VARS

$bg-color-primary: #ffffff;
$bg-color-secondary: mix($primary, white, 30%);


//////////////////////////////////////
// BOOTSTRAP

// import bootstrap vars so that in all our other scss files, we can just import this one settings file and have access to all vars
@import '~bootstrap/scss/_functions'; // the bootstrap variables file also needs access to bootstrap functions
@import '~bootstrap/scss/_mixins'; // bring in mixins so we can use them in our files too
@import '~bootstrap/scss/_variables';


//////////////////////////////////////
// POST BOOTSTRAP OVERIDES

// we need to define these down here as they make use of other bootstrap vars
$body-color: $gray-600;

$headings-color: $primary-dark;
$link-color: $primary;
$link-color-hover: darken($link-color, 8%) !default;
$secondary-link-color: $grey;
$secondary-link-color-hover: darken($secondary-link-color, 8%) !default;

$btn-font-weight: $font-weight-bold;
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;
$input-border-color: $primary-lighter; //#ddd;//$primary;

// $input-box-shadow: 0px 4px 10px 0 rgba(0,0,0,0.1);
$input-box-shadow: none; //inset 1px 2px 7px 0 rgba(0, 0, 0, 0.1);
$input-focus-box-shadow: $input-box-shadow;