.login__heading {
  margin-top: 20px;
}

.login__logo {
  width: 80px;
  height: auto;
  display:inline-block;
  margin-left: 50px;

  @include media-breakpoint-down(sm) {
    width: 50px;
    margin-left: 30px;
  }
}

.login__text {
  margin-right: 60px;

  @include media-breakpoint-down(sm) {
    margin-right: 40px;
  }
}

.login-page{
  background: $body-bg url('/images/road-and-houses.svg') right -100px bottom -50px no-repeat;
  background-size: 800px;
  height: 100%;
  @media screen and (max-height: 850px){
    background-position: top 400px right -100px;
  }

  padding-top: 20px;
}
