// My Bootstrap configuration
@import 'settings';
// Bootstrap library
@import '~bootstrap/scss/bootstrap';

////////////////////////////////
// USING THIS FILE
//
// Currently using this file as a general holder for various styles which haven't yet warranted breaking out into their own files, and are not specifically related to component level scss files (or atleast not to ones we have in our src dir)
// It also houses settings and style overrides for the bootstrap library
////////////////////////////////



// SIZING UTILITY

// This variable affects the `.mh-*`, `.mw-*`, `.minh-*` and `.minw-*` classes.
$max-sizes: () !default;
$max-sizes: map-merge((
  3: 3rem,
  5: 5rem,
  10: 10rem,
  20: 20rem,
  30: 30rem,
  40: 40rem,
  50: 50rem,
  60: 60rem,
  70: 70rem,
  80: 80rem,
), $max-sizes);
// add REM BASED max/min-width and max/min-height utils, equivelent to bootstrap default height/width utils
@each $prop, $abbrev in (max-width: mw, max-height: mh, min-width: minw, min-height: minh) {
  @each $size, $length in $max-sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}


html {
  height: 100%;
}

// LAYOUT
.container-fluid-max{
  max-width: 1200px;
}

.center{
  margin-left: auto;
  margin-right: auto;
}

.row-space-items{
  > [class*="col"]{
    margin-bottom: $grid-gutter-width;
  }
}
.row.row-nowrap{
  flex-wrap: nowrap
}

.col-fill{
  flex-shrink: 1;
  flex-grow: 1;
}

.row{
  @include media-breakpoint-down(xs) {
    margin-right: -$sxs;
    margin-left: -$sxs;
  }
  .col,
  [class*="col"]{
    @include media-breakpoint-down(xs) {
      padding-right: $sxs;
      padding-left: $sxs;
    }
  }
}


// IMAGES

img,
iframe,
embed,
object{
  max-width: 100%;
}

svg{
  width: 100%;
}

// BUTTONS

.btn-xs,
.btn-group-xs > .btn {
  padding: 0.15rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-secondary{
  background: transparent;
  border-color: transparent;
  // color: $link-color;
  color: $grey-middark;

  &:hover, &:focus, &:active,
  &.btn-secondary:not(:disabled):not(.disabled):active,
  &.btn-secondary:not(:disabled):not(.disabled).active,
  .show > &.btn-secondary.dropdown-toggle{
    background: transparent;
    border-color: transparent;
    color: $link-hover-color;
  }
}

.btn-circle{
  color: $grey-light;
  border-radius: 50%;
  border: 2px solid currentColor;
  height: 2.75em;
  width: 2.75em;

  .icon{
    top: 0;
  }
}


// FORMS

label{
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  font-size: 0.85em;
}

form{
  > .nav:last-child{
    margin-top: $smd;
  }
  label{
    small{
      color: $text-muted;
    }
  }
}
::placeholder{
  font-style: italic;
  color: red;
}
.form-control{
  text-align: inherit;
  font-weight: inherit;
  padding: $table-cell-padding-sm;
}
.form-control--highlight{
  border-color: $primary;
}
.form-control-no-browser-helpers{
  // remove browser helper UI buttons on number fields
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number] {
    -moz-appearance:textfield;
  }
}
.invalid-feedback,
.valid-feedback{
  font-weight: 500;
}

h1,h2,h3,h4,h5,h6{
  input,
  .form-control{
    font-size: 1em;
  }
}


// CARDS

.card{

  &.bg-glass{
    background: rgba($card-bg, 0.5);
  }
  &.bg-transparent{
    background: transparent;
  }
  &.card-raised{
    box-shadow: 0 3px 7px 0 rgba(0,0,0,0.2);
  }
  &.card-highlight{
    border: 2px solid $primary;
  }
}

.card-header{
  .card-title{
    margin-bottom: 0;
  }
}

.card-body{
  @include media-breakpoint-down(xs) {
    padding: 1rem 0.5rem;
  }
}


// TABLES
$lv-table-radius: 4px;

.table-cells-align-middle{
  td{
    vertical-align: middle;
  }
}
.table thead th,
.table thead td{
  border-top: none;

  label, h1,h2,h3,h4,h5,h6{
    margin-bottom:0;
  }
}
.table-borderless{
  td,
  th{
    border: none;
  }
}
.table-headed{
  thead th,
  thead td{
    background: $primary-lighter;

    &:first-child{
      // border-radius: $lv-table-radius 0 0 $lv-table-radius;
    }
    &:last-child{
      // border-radius: 0 $lv-table-radius $lv-table-radius 0;
    }
  }
}

$lv-accentuated-table-bg: mix($primary-offwhite, #fff);
.table-accentuated{
  background: $lv-accentuated-table-bg;
  border: 2px solid $primary-lighter;
  border-radius: $lv-table-radius;
  font-style: italic;
}
.row-accentuated{
  td, th{
    background: $lv-accentuated-table-bg;
    font-style: italic;
  }
}




// TYPOGRAPHY

// this is taken from boostrap to replicate the styles applied to <a> tags... bootstrap doesn't give us a class though
%as-link-style{
  cursor: pointer;
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
}
%as-link-hover-style{
  color: $link-hover-color;
  text-decoration: $link-hover-decoration;
}
%as-link,
.as-link{
  @extend %as-link-style;

  h1,h2,h3,h4,h5,h6{
    color: inherit
  }

  &:not(.btn){
    @include hover {
      @extend %as-link-hover-style;
    }
  }
}

.allcaps{
  text-transform: uppercase;
  letter-spacing: 0.01em;
}
.nowrap{
  white-space: nowrap !important;
}
.italic{
  font-style: italic !important;
}

.match-input{
  padding: $input-padding-y 0; // note we are only matching the vertical padding, as we don't have borders to actually pad in from so horizontal padding makes yuck visual alignments
  line-height: $input-line-height;
}

b, strong, .bold{
  font-weight: $font-weight-bold;
}
small, .small {
  font-weight: inherit;
}
.tiny{
  font-size: 60%;
}
.line-height-1{
  line-height: 1;
}


@include media-breakpoint-down(sm) {
  h1, .h1{
    font-size: 1.5rem;
  }
  h2, .h2{
    font-size: 1.35rem;
  }
  h3, .h3{
    font-size: 1.25rem;
  }
  h4, .h4{
    font-size: 1.15rem;
  }
  h5, .h5{
    font-size: 1.075rem;
  }
}

.swatch-title{
  background: url('/images/title-paint-swatch2.svg') no-repeat;
  // background-size: contain;
  background-size: 100% 100%;
  color: #fff;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.8em 0 0.9em;
  text-align: center;
  min-width: 7em;
}
.swatch-title-inline{
  display: inline-flex;
  padding: 0.8em 1em 0.9em;
}
.swatch-title--main{
  margin-bottom: $ssm;

  @include media-breakpoint-up(sm) {
    margin-bottom: $smd;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: $smd*1.5;
  }

  // if title is after a back button
  .back-button-top-container + &{
    margin-left: 100px;

    @include media-breakpoint-up(sm) {
      margin-left: 0;
    }
  }
}

.swatch-title--pale{
  background: url('/images/title-paint-swatch2-pale.svg') no-repeat;
  background-size: 100% 100%;
  color: inherit;
}

.swatch-title--main-small{
  font-size: 1.2rem;
  margin-bottom: $ssm;

  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
}


// ANIMATIONS
.anim-pulsate{
  animation: pulsate 2s infinite ease-in-out;

  &:hover{
    -webkit-animation-play-state:paused;
    -moz-animation-play-state:paused;
    -o-animation-play-state:paused;
    animation-play-state:paused;
  }
}
@keyframes pulsate{
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0.95);
  }
}


// NAVIGATION

.nav-space-items{
  .nav-link{
    padding: 0;
  }
  .nav-item{
    margin: 0 $ssm;
  }
}

.nav-tabs{
  border-bottom: 1px solid $primary-light;
  padding: 0 $ssm;

  .nav-item{
    a,
    .btn{
      padding: $ssm;
      border: 1px solid transparent;

      @include media-breakpoint-down(xs) {
        padding: $sxs;
        font-size: 85%;

        .icon{
          font-size: 1.5rem;
        }
      }
    }

    &.active{
      a,
      .btn{
        border-top-color: $primary-light;
        border-right-color: $primary-light;
        border-left-color: $primary-light;
        border-bottom-color: #fff;
      }
    }
  }
}




//
// BOOTSTRAP
//
.dropdown{
  display: inline-block;
}
.dropdown-menu--overscroll{
  max-height: 50vh;
  overflow: auto;
}




//
//  MISCELLANEOUS
//

.back-button-top-container{
  position:relative;
  height: 0;

  &--no-collapse{
    height: 5rem;
  }
}
.back-button-top{
  @include media-breakpoint-up(sm) {
    position: absolute !important;
    z-index: 10;
    // top: 4.5rem;
  }
}

@import "./imports.scss";
