.avatar-select-list {
  margin: $smd 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.avatar-select {
  position: relative;
  margin: 0 $sxs $ssm;
  width: 40%;
  max-width: 160px;
  color: $primary-dark;

  &.avatar-select--active,
  &:hover {
    color: $primary;
  }

  label {
    cursor: pointer;
  }

  input {
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
  }
}
