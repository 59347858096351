.progress-wrapper{
  display: inline-block;
  position: relative;

  svg{
    display:block;
  }

  &--circular{
    border-radius: 50%;
  }
  &--with-bg{
    background: #fff;
    box-shadow: 0 0 0 8px #fff;
  }
  &--danger{
    .CircularProgressbar .CircularProgressbar-trail{
      stroke: lighten($danger, 30%);
    }
    .CircularProgressbar .CircularProgressbar-path{
      stroke: $danger;
    }
  }
}

.progress__icon{
  position:absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
}



//
// Circular progress bar css overrides
//

.CircularProgressbar .CircularProgressbar-text{
  fill: currentColor;
}
.CircularProgressbar .CircularProgressbar-path{
  stroke: $success;
}
