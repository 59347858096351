.property-detail-progress{
  width:120px;
  max-width:100%;
  margin-top: -80px;
  background: #fff;
  padding: 8px;
  margin-left: -($grid-gutter-width/2);

  @include media-breakpoint-up(sm) {
    width: 80px;
    margin-top: 0;
    max-width:none;
    padding: 0;
    margin-left: 0;
  }
  @include media-breakpoint-up(md) {
    width: 100px;
  }
}
.property-detail{
  display:flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(sm) {
    flex-wrap: nowrap;
  }
}
.property-detail-info{
  padding: $grid-gutter-width / 2;
  flex-grow: 1;
  background: #fff;

  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-width;
  }

  .property-detail-reduced &{
    padding: $grid-gutter-width / 2;
  }
}
.property-detail-info__row{
  @include media-breakpoint-up(sm) {
    flex-wrap: nowrap;
  }
}
.property-detail-image{
  background: #fff;
  height: 120px;
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align:right;

  @include media-breakpoint-up(sm) {
    // overflow: visible; //leave overflow hidden to counter annoying chrome bug where sometimes the image inside doesn't respect max-height 100% of this container
    width: auto;
    max-width: 30%;
    order: 2;
    height: auto;
    max-height: 240px;
  }

  img{
    @include media-breakpoint-down(xs) {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }

    @include media-breakpoint-up(sm) {
      max-height: 100%; //note sometimes chrome doesn't respect this, and spills the image over the container, hence leaving overflow hidden on the parent
      width: auto;
    }
  }
}
.property-detail-image--default{
  background: $primary-light url("/images/property-avatar.svg") no-repeat;
  background-size: 20%;// contain;
  background-position: center;
  // padding-bottom: 66.7%;
  // height:0;
}
.property-detail-nav{
  margin-left: -($btn-padding-x);
}
.property-detail-nav--right{
  margin-left: 0;
  margin-right: -($btn-padding-x);
  justify-content: flex-end;
}

.btn.project-list-add{
  font-size: 24px;
  width: 10em;
  height: 8em;

  @include media-breakpoint-down(xs) {
    font-size: 16px;
    width: 10em;
    height: 8em;
  }
}

.project{
  text-align: center;
}

.project-preview-image{
  background: $primary-light url("/images/project-avatar.svg") no-repeat;
  background-size: cover;
  background-position: center;
  // padding-bottom: 66.7%;
  padding-bottom: 50%;
  height:0;
}
.project-preview-image-default{
  background-size: 35%;
}
.project-preview-details{
  position: relative;
}
.project-progress{
  position: absolute;
  top: 0;
  left:$sxs;
  transform: translate(0, -60%);
  width: 46px;

  .CircularProgressbar .CircularProgressbar-text{
    font-size: 28px;
  }
}

.project-title{
  margin-top: ($sxs / 2);
  position: relative;
  font-weight: $font-weight-bold;
}
