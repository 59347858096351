.icon{
  font-size: 1.5em;
  vertical-align: middle;

  .btn &{
    line-height: 0.5em;
    position: relative;
    top: -0.05em;
  }

  &--size-xs{
    font-size: 0.65rem;
  }
  &--size-md{
    font-size: 2rem;
  }
  &--size-lg{
    font-size: 4rem;
  }
  &--size-xl{
    font-size: 6rem;
  }
}
